@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.roleSelection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}

.radioLabel {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, border-color 0.3s;
  width: 135px;
  text-align: center;
}

.radioInput {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.radioLabel:hover {
  background-color: #1a8977;
  border-color: #1a8977;
  color: white;
}

.radioInput:checked+.radioLabel {
  background-color: #1a8977;
  border-color: #1a8977;
  color: white;
}

.roleSelectionText {
  margin: 30px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.roleSelectionTextOr {
  margin: 10px 0;
  font-size: 16px;
  text-align: center;
}